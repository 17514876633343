/* You can add global styles to this file, and also import other style files */
@import 'fonts';
/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons';

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

.modal-wf-lg {
  .modal-dialog {
    height: 555px;
    max-height: 555px;

    .modal-content {
      .modal-body-lg.no-footer {
        max-height: 420px;
        height: 420px;
      }
    }
  }
}
